import { EAdminRoles, ERouteType, RouteItem } from 'shared/types';
import React, { ComponentType, LazyExoticComponent } from 'react';

const getLazyPage = (path: string): LazyExoticComponent<ComponentType> =>
  React.lazy((): Promise<{ default: ComponentType }> => import(`pages/${path}`));

export const ROUTES: RouteItem[] = [
  {
    type: ERouteType.DEFAULT,
    path: '/',
    component: getLazyPage('main-page/MainPage')
  },
  {
    type: ERouteType.NOT_GUARDED,
    path: '/login',
    component: getLazyPage('login-page/LoginPage')
  },
  {
    type: ERouteType.DEFAULT,
    path: '/not-found',
    component: getLazyPage('not-found-page/NotFoundPage')
  },
  {
    type: ERouteType.GUARDED,
    path: '/sessions',
    component: getLazyPage('sessions-page/SessionsPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    type: ERouteType.GUARDED,
    path: '/schools',
    component: getLazyPage('schools-page/SchoolsPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN]
  },
  {
    type: ERouteType.GUARDED,
    path: '/admins',
    component: getLazyPage('admins-page/AdminsPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN]
  },
  {
    type: ERouteType.GUARDED,
    path: '/logs',
    component: getLazyPage('logs-page/LogsPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    type: ERouteType.GUARDED,
    path: '/pdf-upload',
    component: getLazyPage('pdf-upload-page/PDFUploadPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    type: ERouteType.DEFAULT,
    path: '/reset-password',
    component: getLazyPage('reset-password-page/ResetPasswordPage')
  },
  {
    type: ERouteType.NOT_GUARDED,
    path: '/forgot-password',
    component: getLazyPage('forgot-password-page/ForgotPasswordPage')
  },
  {
    type: ERouteType.GUARDED,
    path: '/vr-users',
    component: getLazyPage('vr-users-page/VrUsersPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    type: ERouteType.GUARDED,
    path: '/reports/sessions',
    component: getLazyPage('sessions-page/SessionsReportPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    type: ERouteType.GUARDED,
    path: '/reports/users',
    component: getLazyPage('vr-users-page/VrUsersReportPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    type: ERouteType.GUARDED,
    path: '/reports/logs',
    component: getLazyPage('logs-page/LogsReportPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    type: ERouteType.GUARDED,
    path: '/help',
    component: getLazyPage('help-page/HelpPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    type: ERouteType.GUARDED,
    path: '/settings',
    component: getLazyPage('settings-page/SettingsPage'),
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  }
];
